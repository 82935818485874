import Head from '@jetshop/core/components/Head';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import { styled } from 'linaria/react';
import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import { theme } from '../Theme';
import blocksStartpage from '../Theme/ContentEditor/blocksStartpage';
import startPageQuery from './StartPageQuery.gql';
import useSetBodyClass from '../Theme/useSetBodyClass';
import getPropsOrFieldValues from '../Theme/Utils/getPropsOrFieldValues';
import HelloRetailFetcher, {
  getBoxLabelByNiceName
} from '../Theme/HelloRetail/HelloRetail';
import HelloRetail from '../Theme/HelloRetail';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
  .helloretail-startpage {
    max-width: 80rem;
    margin: auto;
  }
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const HelloRetailWrapper = styled('section')`
  > div {
    padding-top: 24px;
    max-width: 80rem;
    margin: auto;
  }
`;

function stripHtml(htmlString) {
  if (!htmlString) {
    console.warn('No title for hello retail box');
    return '';
  }
  return htmlString.replace(/<[^>]*>/g, '').trim();
}

const getHelloRetailData = helloRetailItems => {
  const startpageBoxes = helloRetailItems.map((item, index) => {
    const helloRetailLabelProperty = item?.properties?.find(
      prop => prop.name === 'helloRetailLabel'
    );

    const boxLabelNiceName = getPropsOrFieldValues(helloRetailLabelProperty)
      ?.value;

    const helloRetailNames = helloRetailItems.map(item => {
      return item.children[1].properties[0].value;
    });

    return {
      id: getBoxLabelByNiceName(boxLabelNiceName),
      name: stripHtml(helloRetailNames[index].value)
    };
  });

  return startpageBoxes;
};

const PreFetchHelloRetail = ({ children, helloRetailItems }) => {
  const helloRetailLabelKeys = helloRetailItems?.map(item => item.id) || [];
  return (
    <HelloRetailFetcher boxKeyLabels={helloRetailLabelKeys}>
      {items => <>{children(items)}</>}
    </HelloRetailFetcher>
  );
};

const StartPage = ({ startPageId }) => {
  useSetBodyClass('start');
  const [showHelloRetail, setShowHelloRetail] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHelloRetail(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <StartPageWrapper>
      <Query
        variables={startPageId && { startPageId: startPageId }}
        query={startPageQuery}
        fetchPolicy="cache-first"
      >
        {result => {
          const { loading, data } = result;
          if (loading) return <LoadingPage />;
          if (!data) return null;

          const helloRetailContentEditorBlockKey = 'Hello Retail Section';

          const helloRetailData = data?.startPage?.data?.items?.filter(
            items => {
              return items.type === helloRetailContentEditorBlockKey;
            }
          );

          const helloRetailDataItems = getHelloRetailData(helloRetailData);

          return data && data.startPage && data.startPage.content ? (
            <>
              <PreFetchHelloRetail helloRetailItems={helloRetailDataItems}>
                {hellRetailItems => {
                  return (
                    <StartPageRenderer
                      result={result}
                      startPageComponents={{
                        ...blocksStartpage,
                        ...(hellRetailItems && showHelloRetail
                          ? {
                              [helloRetailContentEditorBlockKey]: () => {
                                return (
                                  <HelloRetailWrapper
                                  >
                                    <HelloRetail
                                      helloRetailBoxes={helloRetailDataItems.map(
                                        item => ({
                                          key: item.id,
                                          title: item.name
                                        })
                                      )}
                                    />
                                  </HelloRetailWrapper>
                                );
                              }
                            }
                          : null)
                      }}
                    />
                  );
                }}
              </PreFetchHelloRetail>

              <MaxWidth>
                <Head data={data.startPage.head} />
              </MaxWidth>
            </>
          ) : null;
        }}
      </Query>
    </StartPageWrapper>
  );
};

export default StartPage;
