import React from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { useAddwish } from '@jetshop/flight-addwish';
import addwishProductsQuery from './AddwishProductQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
// import { getChannelKeyById } from '../Utils/getChannelKeyById';

// const dummyData = {
//   k5da9585f62e08256acb87be7: {
//     countAfterSource:
//       '0. FIXED: 0; 1. BOUGHT_WITH: 0; 2. VIEWED_WITH: 0; 3. RETARGETED: 3; 4. BOUGHT_WITH: 5',
//     items: [
//       {
//         url:
//           'https://www.smycka.se/smycken/charm-armband-classic-medium#aw_source=pb-632ad4653366af613607fb67%7C0%7C3%7C0',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/X0031-001-12_ny.jpg?timestamp=1591879433',
//         title: 'Charm-armband classic medium',
//         priceLowered: false,
//         onSale: false,
//         price: 549,
//         priceFormatted: '549',
//         priceExVatFormatted: '',
//         previousPrice: 449,
//         previousPriceFormatted: '449',
//         shopPreviousPrice: 549,
//         shopPreviousPriceFormatted: '549',
//         oldPriceExVatFormatted: '',
//         oldPrice: 549,
//         oldPriceFormatted: '549',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Thomas Sabo',
//         productNumber: 'S001605118',
//         extraData: {
//           filterMaterial: 'Sterlingsilver',
//           itemNumber: '7316',
//           gender: 'unisex',
//           isGiftCard: 'false',
//           subName: 'Thomas Sabo',
//           filterTillVem: 'Unisex',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/moonlight-grapes-bracelet#aw_source=pb-632ad4653366af613607fb67%7C1%7C3%7C1',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/pack__10014405-MOONLIGHT-GRAPES-BRACELET-STERLING-SILVER.png?timestamp=1602254710',
//         title: 'Moonlight Grapes bracelet',
//         priceLowered: false,
//         onSale: false,
//         price: 2750,
//         priceFormatted: '2 750',
//         priceExVatFormatted: '',
//         previousPriceFormatted: '',
//         shopPreviousPrice: 2750,
//         shopPreviousPriceFormatted: '2 750',
//         oldPriceExVatFormatted: '',
//         oldPrice: 2750,
//         oldPriceFormatted: '2 750',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Georg Jensen',
//         productNumber: 'S001996102',
//         extraData: {
//           filterMaterial: 'Oxiderat sterlingsilver',
//           itemNumber: '11275',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'Georg Jensen',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/dew-hoops-gold#aw_source=pb-632ad4653366af613607fb67%7C2%7C3%7C2',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/dewear101.jpg?timestamp=1607527641',
//         title: 'Dew Hoops Gold',
//         priceLowered: false,
//         onSale: false,
//         price: 1695,
//         priceFormatted: '1 695',
//         priceExVatFormatted: '',
//         previousPrice: 1356,
//         previousPriceFormatted: '1 356',
//         shopPreviousPrice: 1695,
//         shopPreviousPriceFormatted: '1 695',
//         oldPriceExVatFormatted: '',
//         oldPrice: 1695,
//         oldPriceFormatted: '1 695',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Emma Israelsson',
//         productNumber: 'S002088693',
//         extraData: {
//           filterMaterial: '18K förgylld mässing',
//           itemNumber: '41808',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'Emma Israelsson',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/925-sterling-silver-zirconia-8#aw_source=pb-632ad4653366af613607fb67%7C3%7C4%7C0',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/1850-051-14.jpg?timestamp=1615286370',
//         title: 'Charm-hängsmycke stjärna stenar silver',
//         priceLowered: false,
//         onSale: false,
//         price: 449,
//         priceFormatted: '449',
//         priceExVatFormatted: '',
//         previousPrice: 399,
//         previousPriceFormatted: '399',
//         shopPreviousPrice: 449,
//         shopPreviousPriceFormatted: '449',
//         oldPriceExVatFormatted: '',
//         oldPrice: 449,
//         oldPriceFormatted: '449',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Thomas Sabo',
//         productNumber: 'S002076138',
//         extraData: {
//           filterMaterial: 'Sterlingsilver',
//           itemNumber: '10716',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'Thomas Sabo',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/charm-armband-classic-large#aw_source=pb-632ad4653366af613607fb67%7C4%7C4%7C1',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/X0031-001-12_ny.jpg?timestamp=1591879433',
//         title: 'Charm-armband classic large',
//         priceLowered: false,
//         onSale: false,
//         price: 599,
//         priceFormatted: '599',
//         priceExVatFormatted: '',
//         previousPrice: 499,
//         previousPriceFormatted: '499',
//         shopPreviousPrice: 599,
//         shopPreviousPriceFormatted: '599',
//         oldPriceExVatFormatted: '',
//         oldPrice: 599,
//         oldPriceFormatted: '599',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Thomas Sabo',
//         productNumber: 'S001638156',
//         extraData: {
//           filterMaterial: 'Sterlingsilver',
//           itemNumber: '8684',
//           gender: 'unisex',
//           isGiftCard: 'false',
//           subName: 'Thomas Sabo',
//           filterTillVem: 'Unisex',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/berlocker/charm-hangsmycke-hjarta-4#aw_source=pb-632ad4653366af613607fb67%7C5%7C4%7C2',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/0766-001-12.jpg?timestamp=1614872078',
//         title: 'Charm-hängsmycke hjärta',
//         priceLowered: false,
//         onSale: false,
//         price: 299,
//         priceFormatted: '299',
//         priceExVatFormatted: '',
//         previousPrice: 239,
//         previousPriceFormatted: '239',
//         shopPreviousPrice: 299,
//         shopPreviousPriceFormatted: '299',
//         oldPriceExVatFormatted: '',
//         oldPrice: 299,
//         oldPriceFormatted: '299',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Thomas Sabo',
//         productNumber: 'S001644261',
//         extraData: {
//           filterMaterial: 'Silver',
//           itemNumber: '6594',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'Thomas Sabo',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/berlocker/charm-hangsmycke-kloverblad-silver#aw_source=pb-632ad4653366af613607fb67%7C6%7C4%7C3',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/0884-001-12.jpg?timestamp=1614009942',
//         title: 'Charm-hängsmycke klöverblad',
//         priceLowered: false,
//         onSale: false,
//         price: 249,
//         priceFormatted: '249',
//         priceExVatFormatted: '',
//         previousPrice: 199,
//         previousPriceFormatted: '199',
//         shopPreviousPrice: 249,
//         shopPreviousPriceFormatted: '249',
//         oldPriceExVatFormatted: '',
//         oldPrice: 249,
//         oldPriceFormatted: '249',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Thomas Sabo',
//         productNumber: 'S001750580',
//         extraData: {
//           filterMaterial: 'Silver',
//           itemNumber: '4373',
//           gender: 'unisex',
//           isGiftCard: 'false',
//           subName: 'Thomas Sabo',
//           filterTillVem: 'Unisex',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/charm-pendant-letter-e#aw_source=pb-632ad4653366af613607fb67%7C7%7C4%7C4',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/0179-001-12.jpg?timestamp=1613138297',
//         title: 'Charm-hängsmycke bokstaven E',
//         priceLowered: false,
//         onSale: false,
//         price: 299,
//         priceFormatted: '299',
//         priceExVatFormatted: '',
//         previousPrice: 239,
//         previousPriceFormatted: '239',
//         shopPreviousPrice: 299,
//         shopPreviousPriceFormatted: '299',
//         oldPriceExVatFormatted: '',
//         oldPrice: 299,
//         oldPriceFormatted: '299',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Thomas Sabo',
//         productNumber: 'S001616261',
//         extraData: {
//           filterMaterial: 'Sterlingsilver',
//           itemNumber: '1801',
//           gender: 'unisex',
//           isGiftCard: 'false',
//           subName: 'Thomas Sabo',
//           filterTillVem: 'Unisex',
//           bestsellersForSorting: '--'
//         }
//       }
//     ]
//   },
//   k5fc63e01ac73f46723a2c8cb: {
//     countAfterSource: '0. FIXED: 0; 1. MANUAL: 1; 2. TOP: 7',
//     items: [
//       {
//         url:
//           'https://www.smycka.se/smycken/claudia-hangsmycke-18krg-sotvattensparla-8mm#aw_source=pb-6315ed7eae807b5f1f163e57%7C0%7C1%7C0',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/Claudia_parlhange_rg.jpg',
//         title: 'Claudia hänge RG m. sötvattenspärla 8mm',
//         priceLowered: false,
//         onSale: false,
//         price: 1990,
//         priceFormatted: '1 990',
//         priceExVatFormatted: '',
//         previousPrice: 1592,
//         previousPriceFormatted: '1 592',
//         shopPreviousPrice: 1990,
//         shopPreviousPriceFormatted: '1 990',
//         oldPriceExVatFormatted: '',
//         oldPrice: 1990,
//         oldPriceFormatted: '1 990',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Love to Love',
//         productNumber: 'S002050828',
//         extraData: {
//           filterMaterial: '18K rött guld och sötvattenspärla',
//           itemNumber: '4425',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'Love to Love',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/petite-papillion-8#aw_source=pb-6315ed7eae807b5f1f163e57%7C1%7C2%7C0',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/GJ_S123_PetitePapillion_Armb.jpg?timestamp=1620317184',
//         title: 'Petite papillion armband 4 fjärilar',
//         priceLowered: false,
//         onSale: false,
//         price: 690,
//         priceFormatted: '690',
//         priceExVatFormatted: '',
//         previousPrice: 650,
//         previousPriceFormatted: '650',
//         shopPreviousPrice: 690,
//         shopPreviousPriceFormatted: '690',
//         oldPriceExVatFormatted: '',
//         oldPrice: 690,
//         oldPriceFormatted: '690',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Carolina Gynning',
//         productNumber: 'S001908130',
//         extraData: {
//           filterMaterial: 'Sterlingsilver',
//           itemNumber: '46987',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'Carolina Gynning',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/klockor/timeless-gold#aw_source=pb-6315ed7eae807b5f1f163e57%7C2%7C2%7C1',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/MO874.jpg?timestamp=1639558551',
//         title: 'Timeless Gold',
//         priceLowered: false,
//         onSale: false,
//         price: 1599,
//         priceFormatted: '1 599',
//         priceExVatFormatted: '',
//         previousPriceFormatted: '',
//         shopPreviousPrice: 1599,
//         shopPreviousPriceFormatted: '1 599',
//         oldPriceExVatFormatted: '',
//         oldPrice: 1599,
//         oldPriceFormatted: '1 599',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Mockberg',
//         productNumber: 'S002105218',
//         extraData: {
//           itemNumber: '52226',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'Mockberg',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/two-hearts-necklace#aw_source=pb-6315ed7eae807b5f1f163e57%7C3%7C2%7C2',
//         imageUrl: 'https://www.smycka.se/pub_images/small/5409742642-S.jpg',
//         title: 'Two hearts necklace',
//         priceLowered: false,
//         onSale: false,
//         price: 690,
//         priceFormatted: '690',
//         priceExVatFormatted: '',
//         previousPrice: 552,
//         previousPriceFormatted: '552',
//         shopPreviousPrice: 690,
//         shopPreviousPriceFormatted: '690',
//         oldPriceExVatFormatted: '',
//         oldPrice: 690,
//         oldPriceFormatted: '690',
//         currency: 'SEK',
//         ean: '',
//         brand: 'August',
//         productNumber: 'S001811067',
//         extraData: {
//           filterMaterial: 'Silver',
//           itemNumber: '6586',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'August',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/halsband-pansarlank-50-cm#aw_source=pb-6315ed7eae807b5f1f163e57%7C4%7C2%7C3',
//         imageUrl: 'https://www.smycka.se/pub_images/small/540PN1245S.jpg',
//         title: 'Halsband pansarlänk 50 cm',
//         priceLowered: false,
//         onSale: false,
//         price: 1200,
//         priceFormatted: '1 200',
//         priceExVatFormatted: '',
//         previousPrice: 960,
//         previousPriceFormatted: '960',
//         shopPreviousPrice: 1200,
//         shopPreviousPriceFormatted: '1 200',
//         oldPriceExVatFormatted: '',
//         oldPrice: 1200,
//         oldPriceFormatted: '1 200',
//         currency: 'SEK',
//         ean: '',
//         brand: 'August',
//         productNumber: 'S001671866',
//         extraData: {
//           filterMaterial: 'Silver',
//           itemNumber: '9004',
//           gender: 'unisex',
//           isGiftCard: 'false',
//           subName: 'August',
//           filterTillVem: 'Unisex',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/presentkort/presentkort-200#aw_source=pb-6315ed7eae807b5f1f163e57%7C5%7C2%7C4',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/presentkort_bla_200.jpg',
//         title: 'Presentkort',
//         priceLowered: false,
//         onSale: false,
//         price: 200,
//         priceFormatted: '200',
//         priceExVatFormatted: '',
//         previousPriceFormatted: '',
//         shopPreviousPrice: 200,
//         shopPreviousPriceFormatted: '200',
//         oldPriceExVatFormatted: '',
//         oldPrice: 200,
//         oldPriceFormatted: '200',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Smycka',
//         productNumber: 'GIFTCARD1',
//         extraData: {
//           itemNumber: '9414',
//           isGiftCard: 'true',
//           subName: 'Smycka',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/orhangen/a-hoops-fgs-11mm---#aw_source=pb-6315ed7eae807b5f1f163e57%7C6%7C2%7C5',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/hoops11mm_FG_S002100348.jpg?timestamp=1633020175',
//         title: 'Hoops earrings 11 mm gold plated',
//         priceLowered: false,
//         onSale: false,
//         price: 160,
//         priceFormatted: '160',
//         priceExVatFormatted: '',
//         previousPrice: 140,
//         previousPriceFormatted: '140',
//         shopPreviousPrice: 160,
//         shopPreviousPriceFormatted: '160',
//         oldPriceExVatFormatted: '',
//         oldPrice: 160,
//         oldPriceFormatted: '160',
//         currency: 'SEK',
//         ean: '',
//         brand: 'August',
//         productNumber: 'S002100348',
//         extraData: {
//           filterMaterial: 'Silver',
//           itemNumber: '49264',
//           gender: 'female',
//           isGiftCard: 'false',
//           subName: 'August',
//           filterTillVem: 'Dam',
//           bestsellersForSorting: '--'
//         }
//       },
//       {
//         url:
//           'https://www.smycka.se/smycken/charm-armband-155-cm-2#aw_source=pb-6315ed7eae807b5f1f163e57%7C7%7C2%7C6',
//         imageUrl:
//           'https://www.smycka.se/pub_images/small/X0163-001-12.jpg?timestamp=1591881310',
//         title: 'Charm-armband 15,5 cm',
//         priceLowered: false,
//         onSale: false,
//         price: 249,
//         priceFormatted: '249',
//         priceExVatFormatted: '',
//         previousPrice: 199,
//         previousPriceFormatted: '199',
//         shopPreviousPrice: 249,
//         shopPreviousPriceFormatted: '249',
//         oldPriceExVatFormatted: '',
//         oldPrice: 249,
//         oldPriceFormatted: '249',
//         currency: 'SEK',
//         ean: '',
//         brand: 'Thomas Sabo',
//         productNumber: 'S001638160',
//         extraData: {
//           filterMaterial: 'Sterlingsilver',
//           itemNumber: '8554',
//           gender: 'unisex',
//           isGiftCard: 'false',
//           subName: 'Thomas Sabo',
//           filterTillVem: 'Unisex',
//           bestsellersForSorting: '--'
//         }
//       }
//     ]
//   }
// };

export const helloRetailBoxes = {
  POPULAR_IN_CATEGORY: 'k5da9585f62e08256acb87beb',
  POPULAR_RIGHT_NOW: 'k5fc63e01ac73f46723a2c8cb',
  SIMILAR_PRODUCTS: 'k5da9585f62e08256acb87be9',
  ALSO_BOUGHT: 'k5da9585f62e08256acb87be6',
  RECOMMENDED_FOR_YOU: 'k5da9585f62e08256acb87be7'
};

export const helloRetailContentEditorLabels = {
  'Recommended for you': helloRetailBoxes['RECOMMENDED_FOR_YOU'],
  'Popular right now': helloRetailBoxes['POPULAR_RIGHT_NOW']
};

const helloRetailBoxMap = {
  sv: {
    [helloRetailBoxes['POPULAR_IN_CATEGORY']]: 'k5da9585f62e08256acb87beb',
    [helloRetailBoxes['POPULAR_RIGHT_NOW']]: 'k5fc63e01ac73f46723a2c8cb',
    [helloRetailBoxes['SIMILAR_PRODUCTS']]: 'k5da9585f62e08256acb87be9',
    [helloRetailBoxes['ALSO_BOUGHT']]: 'k5da9585f62e08256acb87be6',
    [helloRetailBoxes['RECOMMENDED_FOR_YOU']]: 'k5da9585f62e08256acb87be7'
  }
};

export const getBoxLabelByNiceName = niceName => {
  return helloRetailContentEditorLabels?.[niceName];
};

export const getBoxLabelByKey = boxKey => {
  let boxLabel = null;

  Object.values(helloRetailBoxMap).some(channelBoxes => {
    return Object.entries(channelBoxes).some(([label, key]) => {
      if (key === boxKey) {
        boxLabel = label;
        return true;
      }
      return false;
    });
  });

  return boxLabel;
};

export const HelloRetailSection = () => {};

export const useGetHelloRetailBoxes = (
  boxes = [],
  customPathname = null,
  customHierarchies = null
) => {
  const { selectedChannel } = React.useContext(ChannelContext);
  const { pathname } = useLocation();
  let hierarchies = null;

  const url = `${selectedChannel?.url}${
    customPathname ? customPathname : pathname
  }`;

  if (!customHierarchies) {
    hierarchies = [pathname?.substring(1)?.split('/')];
  }

  // const channelKey = getChannelKeyById(selectedChannel?.id);

  return boxes.reduce(
    (acc, boxKeyLabel) => ({
      ...acc,
      [helloRetailBoxMap?.['sv']?.[boxKeyLabel]]: {
        url,
        hierarchies: customHierarchies ? customHierarchies : hierarchies,
        boxKeyLabel
      }
    }),
    {}
  );
};

export const usePopulateProductBoxes = ({ addWishResponseBoxItems }) => {
  const helloRetailDataItems = addWishResponseBoxItems;
  // const helloRetailDataItems = dummyData;

  const articleNumbers = Object.values(helloRetailDataItems).reduce(
    (acc, box) => {
      return [
        ...acc,
        //  ...(box.items?.map(item => item.extraData?.itemNumber) || [])
        ...(box.items?.map(item => item.productNumber) || [])
      ];
    },
    []
  );

  const { data, loading, error } = useQuery(addwishProductsQuery, {
    variables: { articleNumbers },
    skip: articleNumbers.length === 0
  });

  if (loading || error) {
    return null;
  }

  const result = Object.entries(helloRetailDataItems).reduce(
    (acc, [boxKey, { items }]) => {
      const products = items.map(item => {
        const product = {
          ...data?.products.find(
            product => product.articleNumber === item.productNumber
          )
        };

        if (product) {
          product.addwishData = item;
        }

        return product;
      });

      return {
        ...acc,
        [getBoxLabelByKey(boxKey)]: products
      };
    },
    {}
  );

  return result;
};

const HelloRetailFetcher = ({
  children,
  loading = false,
  boxKeyLabels = null
}) => {
  const helloRetailBoxes = useGetHelloRetailBoxes(boxKeyLabels) ?? [];

  const { addWishResponseBoxItems } = useAddwish({
    boxes: helloRetailBoxes,
    pageLoaded: !loading
  });

  const helloRetailProductBoxes = usePopulateProductBoxes({
    addWishResponseBoxItems
  });

  return children(helloRetailProductBoxes);
};

export default HelloRetailFetcher;
